footer {
    height: 63px;
    background-color: #FCFCFC;
    border-top: 1px solid #B7B7B7;
    margin-top: auto;
    display: flex;
    vertical-align: middle;
}

footer div.footer-content {
    margin: auto 13px !important;
}