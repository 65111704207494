.article-container {
    display: flex;
    flex-direction: row;
    flex-basis: 675px;
}

.left-buffer {
    flex-grow: 1;
}

.right-buffer {
    flex-grow: 0.5;
}

img.article-banner {
    height: 323px;
    width: 100%;
    object-fit: cover;
}

.header-attribution {
    margin-top: 13px;
    color: #6D6D6D;
    font-size: 12;
    font-family: Inconsolata, Consolas, 'Forma DJR Deck';
}

article.article {
    max-width: 675px;
}

article h2 {
    color: #6D6D6D;
    margin-bottom: 14px;
}

article p {
    margin-bottom: 14px;
}

article img {
    margin: 0 auto;
}

.article-read-time {
    margin-top: 26px;
    margin-bottom: -10px;
}

.article-read-time span {
    color: #6D6D6D;
}

img.article-author-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #BDBDBD;
    margin: 0 12px 0 0;
}

.article-author-card {
    display: flex;
    margin-bottom: 21px;
    margin-left: 0;
}

.article-author-by {
    color: #6D6D6D;
}

.article-author-published {
    color: #6D6D6D;
}

.article-author-name {
    margin: -3px 0;
}

.divider {
    height: 1px;
    background-color: #BDBDBD;
}

.article-tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 11px;
}

.article-bottom-section {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
}

.separator {
    height: 1px;
    background-color: #bdbdbd;
}

.article-bottom-links {
    display: flex;
    flex-direction: row;
    gap: 13px;
    margin-top: 13px;
}

.article-bottom-links span {
    color: #BDBDBD;
    margin-bottom: 38px;
}

.related-articles-row {
    display: flex;
    align-items: center;
    gap: 38px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    flex-direction: row;
}

.article-card {
    height: 225px;
}

.article-cta-section p {
    margin: 0;
}

article blockquote {
    margin-top: 50px;
}

article blockquote::before {
    content: '“';
    display: block;
    line-height: 0;
    color: #007D77;
    font-weight: bold;
    font-size: 72px;
}


@media screen and (max-width: 1000px) {
    .article-bottom-section {
        padding: 8px;
    }
    
    .article-container {
        flex-direction: column;
        margin: auto;
        flex-basis: 0;
    }

    .left-bufffer {
        flex-grow: 0;
    }

    .right-buffer {
        flex-grow: 0;
    }

    .article-sidebar-right {
        margin-top: 36px;
    }
}