.glossary-card {
    display: flex;
    height: 150px;
    border-bottom: 1px solid #BDBDBD;
    min-width: 283px;
    max-width: 300px;
}

.glossary-card-text {
    flex-grow: 1;
    padding: 16px 29px 8px 0;
    overflow-y: scroll;
}

.glossary-card-label {
    background-color: black;
    width: 34px;
    min-width: 34px;
    max-width: 34px;
}

.glossary-card-label span {
    position: absolute;
    color: white;
    transform: translateX(-41px) translateY(55px) rotate(90deg);
    font-size: 24px;
    font-weight: 500;
}

p.glossary-card-term {
    font-size: 16px;
}

p.glossary-card-definition {
    font-size: 14px;
}