.curated-set-card {
    display: flex;
    flex-direction: row;
    height: 200px;
    border-bottom: 1px solid #BDBDBD;
    min-width: 283px;
    max-width: 300px;
}

.curated-set-card-left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.curated-set-card-right {
    flex-basis: 27px;
    min-width: 27px;
}

a.curated-article-title {
    text-decoration: none !important;
    margin-bottom: 11px;
    font-family: 'Forma DJR Deck';
}

a.curated-article-title h3 {
    color: black;
    font-size: 18px;
}

.curated-article-card-spacer {
    flex-grow: 1;
}

.curated-article-excerpt {
    font-size: 16px;
    font-family: 'Forma DJR Deck';
}

.curated-article-date {
    margin-bottom: 9px;
    color: #6D6D6D;
}

.curated-set-card-bucket {
    overflow: visible;
    position: absolute;
    transform-origin: 14px 0;
    transform: rotate(90deg) translateX(20px) translateY(-10px);
    font-size: 14px;
    font-family: 'Forma DJR Deck';
}