.article-transcription {
    background-color: rgba(0, 125, 119, 0.1);
    padding: 13px 18px 0 18px;
    max-height: 386px;
    overflow-y: auto;
    margin: 17px 0 31px 0;
}

.transcription-line {
    display: flex;
    margin-bottom: 13px;
}

.transcription-timestamp {
    color: #6D6D6D;
    flex-basis: 130px;
}

.article-transcription h3 {
    font-size: 18px;
    margin-bottom: 13px;
}