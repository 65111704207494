@font-face {
  font-family: "Forma DJR Deck";
  src: url("../public/fonts/Forma-DJR-Deck/FormaDJRDeck-Regular.otf");
}
@font-face {
  font-family: "Forma DJR Deck Bold";
  src: url("../public/fonts/Forma-DJR-Deck/FormaDJRDeck-Bold.otf");
}
@font-face {
  font-family: "Forma DJR Banner";
  src: url("../public/fonts/Forma-DJR-Banner/FormaDJRBanner-Regular.otf");
}
@font-face {
  font-family: "Forma DJR Banner Bold";
  src: url("../public/fonts/Forma-DJR-Banner/FormaDJRBanner-Bold.otf");
}

body {
  margin: auto 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FCFCFC !important;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #00ADA5 !important;
  text-decoration: underline !important;
}

.page-content {
  max-width: 1000px;
  margin: 0 auto;
}

h1 {
  font-family: 'Forma DJR Banner Bold';
  font-size: 30px;
  letter-spacing: 1.8px;
}

.center {
  text-align: center;
}

.search-div {
  margin-top: 24px;
  margin-bottom: 38px;
  display: flex;
  justify-content: space-between;
}

.search-div > input[type='text'] {
  width: 100%;
  margin-right: 23px;
  border-bottom: 1px solid #898989;
  background-color: #FCFCFC;
  padding: 0 40px;
}

.search-magnifying-glass {
  position: absolute;
  margin-top: -5px;
  font-size: 30px;
  color: #898989;
  transform: rotate(-45deg);
}

.bucket-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.bucket-row > a > div > div, .bucket-row > div > div > div {
  padding: 19px 14px;
  display: flex;
  flex-direction: column;
}

.bucket-row > div > div > div:hover {
  color: white;
}

.bucket-row-small {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-size: 10.5pt;
  margin-bottom: 43px;
  flex-wrap: wrap;
}

.bucket-box-title {
  font-size: 18px;
  text-align: center;
  height: 50%;
}

.bucket-box-subtitle {
  font-size: 14px;
  text-align: center;
  height: 50%;
}

.bucket-row a {
  color: black !important;
  text-decoration: none !important;
  margin-bottom: 49px;
}

.glossary-row, .article-row, .curated-article-row {
  display: flex;
  align-items: center;
  gap: 38px;
  margin-bottom: 40px;
  flex-wrap: wrap;
  flex-direction: row;
}

/* PROPER HEADER - CONTENT - FOOTER spacing */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex: 1;
}