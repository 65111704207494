header {
    height: 63px;
    background-color: white;
}

header .logo-wrapper {
    width: 106px;
    padding-top: 23px;
    padding-left: 15px;
    display: block !important;
    flex-grow: 0;
}

header .header-content-wrapper {
    display: flex;
    flex-direction: row;
}

.search-div-header {
    margin-left: 47px;
    margin-top: 18px;
}

.search-div-header input[type='text'] {
    width: 100%;
    border-bottom: 1px solid #898989;
    background-color: #FCFCFC;
    padding: 0 20px;
    font-size: 12px;
    background: transparent;
}

.search-div-header .search-magnifying-glass {
    font-size: 20px;
    margin-top: 0px;
}


