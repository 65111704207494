.article-card {
    display: flex;
    flex-direction: column;
    height: 278px;
    border-bottom: 1px solid #BDBDBD;
    min-width: 283px;
    max-width: 300px;
}

a.article-title {
    text-decoration: none !important;
    margin-bottom: 11px;
    font-family: 'Forma DJR Deck';
}

a.article-title h3 {
    color: black;
    font-size: 18px;
}

.article-card-spacer {
    flex-grow: 1;
}

.article-excerpt {
    font-size: 16px;
    font-family: 'Forma DJR Deck';
}

.article-tags {
    margin-bottom: 11px;
}

.article-date {
    margin-bottom: 35px;
    color: #6D6D6D;
}