.breadcrumb {
    height: 53px;
    padding-left: 23px;
    padding-top: 20px;
}

span.breadcrumb-gt {
    margin: 0 10px;
    color: #AFAFAF;
    font-weight: bold;
}

.breadcrumb a {
    color: black !important;
    text-decoration: none !important;
}